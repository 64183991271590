import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";

import CourseActivity from "~/components/Reports/CourseActivity";
import CourseModule from "~/components/Reports/CourseModule";
import * as ReportModels from "~/components/Reports/ReportModels";
import { List } from "~/components/Reports/ReportModels";
import { ScrollArea } from "~/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table";
import { Button } from "~/components/ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "~/components/ui-rework/dialog";
import { Dropdown } from "~/components/ui-rework/dropdown";
import { Select } from "~/components/ui-rework/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/components/ui-rework/tabs";
import useOverrides from "~/hooks/useOverrides";
import EmptyStateGraphic from "~/images/Reports-Program-Course-Activities-Modules.svg";
import { useStore } from "~/models/Root";

function toPercent(num: number, places = 1): string {
  return (num * 100).toFixed(places) + "%";
}

function getActivityTypeDisplay(type: string): string {
  if (!type) return "Other";
  switch (type.toLowerCase()) {
    case "project":
      return "Project";

    case "discussion":
    case "discussion with research":
    case "discussion without research":
      return "Discussion";

    case "quiz":
      return "Quiz";

    case "assignment":
    case "graded assignment":
      return "Assignment";

    case "exam":
      return "Exam";

    case "class meeting":
    case "synchronous session":
    case "face to face session":
    case "face-to-face session":
      return "Class Meeting";

    case "lab":
      return "Lab";

    case "reading":
      return "Reading";

    case "multimedia":
      return "Multimedia";

    case "other":
    default:
      return "Other";
  }
}

function getGradingStakesDisplay(grading: string): string {
  if (!grading) return "Other";
  switch (grading.toLowerCase()) {
    case "high":
    case "high_stakes":
    case "high stakes":
      return "High";

    case "medium":
    case "medium_stakes":
    case "medium stakes":
      return "Medium";

    case "low":
    case "low_stakes":
    case "low stakes":
      return "Low";

    case "other":
    default:
      return "Other";
  }
}

class ActivitySummaryRow {
  constructor(course: ReportModels.Course, list_id: number) {
    const activities = course.modules?.flatMap((m) => m.activities) || [];
    const totalMinutes = activities.reduce(
      (acc, curr) => acc + curr.tot_minutes,
      0,
    );
    const treeMappings = ReportModels.getTreeMappingsByListId(course, list_id);
    const hoursPerCredit =
      course.credits === 0 ? 0 : totalMinutes / 60 / course.credits;

    this.course_id = course.id;
    this.course_code = course.code;
    this.course_name = course.name;
    this.credits = course.credits;
    this.losCount = treeMappings.length;
    this.losPerCredit = (treeMappings.length / course.credits).toFixed(1);
    this.activityCount = activities.length;
    this.totalCourseMinutes = totalMinutes;
    this.totalCourseMinutesDisplay = ReportModels.formatMinutes(totalMinutes);
    this.hoursPerCredit = hoursPerCredit;
    this.hoursPerCreditDisplay = ReportModels.formatMinutes(hoursPerCredit);
  }

  course_id = 0;
  course_code = "";
  course_name = "";
  credits = 0;
  losCount = 0;
  losPerCredit = "";
  activityCount = 0;
  totalCourseMinutes = 0;
  totalCourseMinutesDisplay = "";
  hoursPerCredit = 0;
  hoursPerCreditDisplay = "";
}

class ActivityTypeRow {
  constructor(course: ReportModels.Course) {
    const totalMinutes =
      course.modules
        ?.flatMap((m) => m.activities)
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) || 0;
    const activities = course.modules?.flatMap((m) => m.activities) || [];

    this.course_id = course.id;
    this.course_code = course.code;
    this.course_name = course.name;
    this.assignmentPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Assignment")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.classMeetingPercent =
      activities
        .filter(
          (a) => getActivityTypeDisplay(a.activity_type) === "Class Meeting",
        )
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.discussionsPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Discussion")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.examPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Exam")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.labPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Lab")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.multimediaPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Multimedia")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.projectPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Project")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.quizPercent =
      activities
        .filter((a) => getActivityTypeDisplay(a.activity_type) === "Quiz")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.readingPercent =
      totalMinutes === 0
        ? 0
        : activities
            .filter(
              (a) => getActivityTypeDisplay(a.activity_type) === "Reading",
            )
            .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes ||
          0;
    this.otherPercent =
      totalMinutes === 0
        ? 0
        : 1.0 -
          (this.assignmentPercent +
            this.classMeetingPercent +
            this.discussionsPercent +
            this.examPercent +
            this.multimediaPercent +
            this.projectPercent +
            this.quizPercent +
            this.readingPercent);
  }

  course_id = 0;
  course_code = "";
  course_name = "";
  assignmentPercent = 0;
  classMeetingPercent = 0;
  discussionsPercent = 0;
  examPercent = 0;
  labPercent = 0;
  multimediaPercent = 0;
  projectPercent = 0;
  quizPercent = 0;
  readingPercent = 0;
  otherPercent = 0;
}

class ActivityGradingStakesRow {
  constructor(course: ReportModels.Course) {
    const totalMinutes =
      course.modules
        ?.flatMap((m) => m.activities)
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) || 0;
    const activities = course.modules?.flatMap((m) => m.activities) || [];

    this.course_id = course.id;
    this.course_code = course.code;
    this.course_name = course.name;
    this.highPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "High")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.mediumPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "Medium")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.lowPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "Low")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
    this.otherPercent =
      activities
        .filter((a) => getGradingStakesDisplay(a.grading_stakes) === "Other")
        .reduce((acc, curr) => acc + curr.tot_minutes, 0) / totalMinutes || 0;
  }

  course_id = 0;
  course_code = "";
  course_name = "";
  highPercent = 0;
  mediumPercent = 0;
  lowPercent = 0;
  otherPercent = 0;
}

class CompModel {
  constructor(data: ReportModels.ProgramData | null, list_id: number | null) {
    if (data === null || list_id === null) return;

    data.courses.forEach((course: ReportModels.Course) => {
      this.activitySummaryRows.push(new ActivitySummaryRow(course, list_id));
      this.activityTypeRows.push(new ActivityTypeRow(course));
      this.activityGradingStakeRows.push(new ActivityGradingStakesRow(course));
    });
  }

  activitySummaryRows: ActivitySummaryRow[] = [];
  activityTypeRows: ActivityTypeRow[] = [];
  activityGradingStakeRows: ActivityGradingStakesRow[] = [];
}

class ModalDetails {
  course: ReportModels.Course = {} as ReportModels.Course;
  list: ReportModels.List = {} as ReportModels.List;
}

const ProgramActivities: React.FC<{
  programData: ReportModels.ProgramData | null;
  forPrint?: boolean | false;
}> = observer(({ programData, forPrint }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalDetails, setModalDetails] = useState<ModalDetails | null>(null);

  const {
    ProgramLabel,
    CourseLabel,
    CoursesLabel,
    ActivityLabel,
    ActivitiesLabel,
  } = useOverrides();
  const programLabel = ProgramLabel();
  const courseLabel = CourseLabel();
  const coursesLabel = CoursesLabel();
  const activityLabel = ActivityLabel();
  const activitiesLabel = ActivitiesLabel();

  function getCourse(id: number): ReportModels.Course {
    return (
      programData?.courses.find((x) => x.id === id) || new ReportModels.Course()
    );
  }

  useEffect(() => {
    if (modalDetails !== null) setModalVisible(true);
    else setModalVisible(false);
  }, [modalDetails]);

  const { getDropdownListReports, setTargetListReports, getTargetListReports } =
    useStore();
  const listItems = getDropdownListReports();
  const targetListVar = getTargetListReports();

  const handleDropdownChange = (newValue: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: List) => item.id === parseInt(newValue),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  const handleSelectChange = (listItemName: string, newValue: string) => {
    const list =
      programData?.mapped_lists.find(
        (item: List) => item.id === parseInt(newValue),
      ) || null;
    if (list) {
      setTargetListReports(list);
    }
  };

  const Model = new CompModel(programData || null, targetListVar?.id || null);

  return (
    <>
      {!targetListVar && listItems.length > 0 ? (
        <div className="h-full flex flex-row gap-9 px-24 justify-center items-center">
          <img
            alt="Course Modules Reports Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px]"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              Select a List
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              Select a list to see a breakdown of the{" "}
              {programLabel?.toLowerCase()} activities
            </p>
            <div className="w-[85%]">
              <Select
                fieldName="list-loader-reports"
                options={listItems}
                placeholder="Select a list"
                testid="course-modules-list-dropdown"
                value={undefined}
                handleChange={handleSelectChange}
              />
            </div>
          </div>
        </div>
      ) : (
        <ScrollArea className="w-full h-full" thumbClassName="bg-gradient-1">
          <div className="py-2">
            <div className="w-fit pl-1">
              {!forPrint && (
                <>
                  {targetListVar && listItems.length > 0 && (
                    <Dropdown
                      options={listItems}
                      placeholder="Select a list"
                      testid="course-modules-list-dropdown"
                      value={targetListVar?.id.toString() || undefined}
                      handleChange={handleDropdownChange}
                      variant="outline"
                    />
                  )}
                </>
              )}
            </div>
            <div className="pr-4">
              {targetListVar && targetListVar.id > 0 && (
                <>
                  <div className="my-6">
                    <div>
                      <b>{programLabel} Name:</b> {programData?.display_name}
                    </div>
                    <div>
                      <b>Number of {coursesLabel}:</b>{" "}
                      {programData?.courses?.length || 0}
                    </div>
                    <div>
                      <b>Number of Credits:</b>{" "}
                      {programData?.courses?.reduce(
                        (acc, curr) => acc + curr.credits,
                        0,
                      ) || 0}
                    </div>
                  </div>

                  <div>
                    <h5 className="text-lg font-bold mb-2">Types by Time</h5>
                    <div className="light h-full overflow-auto rounded-md border border-idesign-navy-120 bg-white">
                      <Table className="bg-white w-full rounded-md overflow-hidden border-collapse border-spacing-0">
                        <TableHeader>
                          <TableRow
                            role="row"
                            className="bg-gradient-1 border-b border-idesign-navy-120"
                          >
                            <TableHead className="text-white font-bold border-r border-idesign-navy-120">
                              Code
                            </TableHead>
                            <TableHead className="text-white font-bold">
                              {courseLabel}
                            </TableHead>
                            <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                              Assignment
                            </TableHead>
                            <TableHead className="center text-white font-bold">
                              Class Meeting
                            </TableHead>
                            <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                              Discussion
                            </TableHead>
                            <TableHead className="center text-white font-bold">
                              Exam
                            </TableHead>
                            <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                              Lab
                            </TableHead>
                            <TableHead className="center text-white font-bold">
                              Multimedia
                            </TableHead>
                            <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                              Project
                            </TableHead>
                            <TableHead className="center text-white font-bold">
                              Quiz
                            </TableHead>
                            <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                              Reading
                            </TableHead>
                            <TableHead className="center text-white font-bold">
                              Other
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {Model?.activityTypeRows.map((row) => (
                            <TableRow
                              key={row.course_id}
                              className="border-y border-idesign-navy-120 hover:bg-ocean-10"
                            >
                              <TableCell>
                                <Button
                                  size="sm"
                                  variant="ghost"
                                  onClick={() =>
                                    setModalDetails({
                                      course: getCourse(row.course_id),
                                      list: targetListVar,
                                    })
                                  }
                                  aria-haspopup="dialog"
                                >
                                  {row.course_code}
                                </Button>
                              </TableCell>
                              <TableCell className="px-2 py-4 border-x border-idesign-navy-120">
                                {row.course_name}
                              </TableCell>
                              <TableCell className="center">
                                {toPercent(row.assignmentPercent)}
                              </TableCell>
                              <TableCell className="center border-x border-idesign-navy-120">
                                {toPercent(row.classMeetingPercent)}
                              </TableCell>
                              <TableCell className="center">
                                {toPercent(row.discussionsPercent)}
                              </TableCell>
                              <TableCell className="center border-x border-idesign-navy-120">
                                {toPercent(row.examPercent)}
                              </TableCell>
                              <TableCell className="center">
                                {toPercent(row.labPercent)}
                              </TableCell>
                              <TableCell className="center border-x border-idesign-navy-120">
                                {toPercent(row.multimediaPercent)}
                              </TableCell>
                              <TableCell className="center">
                                {toPercent(row.projectPercent)}
                              </TableCell>
                              <TableCell className="center border-x border-idesign-navy-120">
                                {toPercent(row.quizPercent)}
                              </TableCell>
                              <TableCell className="center">
                                {toPercent(row.readingPercent)}
                              </TableCell>
                              <TableCell className="center border-l border-idesign-navy-120">
                                {toPercent(row.otherPercent)}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>

                    <div className="mt-6">
                      <h5 className="text-lg font-bold mb-2">
                        {activityLabel} Stakes
                      </h5>
                      <div className="light h-full overflow-auto rounded-md border border-idesign-navy-120 bg-white">
                        <Table className="bg-white w-full rounded-md overflow-hidden border-collapse border-spacing-0">
                          <TableHeader>
                            <TableRow
                              role="row"
                              className="bg-gradient-1 border-b border-idesign-navy-120"
                            >
                              <TableHead className="text-white font-bold">
                                Code
                              </TableHead>
                              <TableHead className="text-white font-bold border-x border-idesign-navy-120">
                                {courseLabel}
                              </TableHead>
                              <TableHead className="center text-white font-bold">
                                High Stakes
                              </TableHead>
                              <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                                Medium Stakes
                              </TableHead>
                              <TableHead className="center text-white font-bold">
                                Low Stakes
                              </TableHead>
                              <TableHead className="center text-white font-bold border-l border-idesign-navy-120">
                                Other
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {Model?.activityGradingStakeRows.map((row) => (
                              <TableRow
                                key={row.course_id}
                                className="border-y border-idesign-navy-120 hover:bg-ocean-10"
                              >
                                <TableCell>
                                  <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() =>
                                      setModalDetails({
                                        course: getCourse(row.course_id),
                                        list: targetListVar,
                                      })
                                    }
                                    aria-haspopup="dialog"
                                  >
                                    {row.course_code}
                                  </Button>
                                </TableCell>
                                <TableCell className="px-2 py-4 border-x border-idesign-navy-120">
                                  {row.course_name}
                                </TableCell>
                                <TableCell className="center">
                                  {toPercent(row.highPercent)}
                                </TableCell>
                                <TableCell className="center border-x border-idesign-navy-120">
                                  {toPercent(row.mediumPercent)}
                                </TableCell>
                                <TableCell className="center">
                                  {toPercent(row.lowPercent)}
                                </TableCell>
                                <TableCell className="center border-l border-idesign-navy-120">
                                  {toPercent(row.otherPercent)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </div>

                    <div className="mt-6">
                      <h5 className="text-lg font-bold mb-2">
                        {programLabel} {activitiesLabel}
                      </h5>
                      <div className="light h-full overflow-auto rounded-md border border-idesign-navy-120 bg-white">
                        <Table className="bg-white w-full rounded-md overflow-hidden border-collapse border-spacing-0">
                          <TableHeader>
                            <TableRow
                              role="row"
                              className="bg-gradient-1 border-b border-idesign-navy-120"
                            >
                              <TableHead className="text-white font-bold">
                                Code
                              </TableHead>
                              <TableHead className="text-white font-bold border-x border-idesign-navy-120">
                                {courseLabel}
                              </TableHead>
                              <TableHead className="center text-white font-bold">
                                Number of Credits
                              </TableHead>
                              <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                                Number of LOs
                              </TableHead>
                              {/* <TableHead className="center">LOs per Credit</TableHead> */}
                              <TableHead className="center text-white font-bold">
                                Number of {activitiesLabel}
                              </TableHead>
                              <TableHead className="center text-white font-bold border-x border-idesign-navy-120">
                                Total Time Allotted
                              </TableHead>
                              <TableHead className="center text-white font-bold">
                                Hours per Credit
                              </TableHead>
                            </TableRow>
                          </TableHeader>
                          <TableBody>
                            {Model?.activitySummaryRows.map((row) => (
                              <TableRow
                                key={row.course_id}
                                className="border-y border-idesign-navy-120 hover:bg-ocean-10"
                              >
                                <TableCell>
                                  <Button
                                    size="sm"
                                    variant="ghost"
                                    onClick={() =>
                                      setModalDetails({
                                        course: getCourse(row.course_id),
                                        list: targetListVar,
                                      })
                                    }
                                    aria-haspopup="dialog"
                                  >
                                    {row.course_code}
                                  </Button>
                                </TableCell>
                                <TableCell className="px-2 py-4 border-x border-idesign-navy-120">
                                  {row.course_name}
                                </TableCell>
                                <TableCell className="center">
                                  {row.credits}
                                </TableCell>
                                <TableCell className="center border-x border-idesign-navy-120">
                                  {row.losCount}
                                </TableCell>
                                {/* <TableCell className="center">{row.losPerCredit}</TableCell> */}
                                <TableCell className="center">
                                  {row.activityCount}
                                </TableCell>
                                <TableCell className="center border-x border-idesign-navy-120">
                                  {row.totalCourseMinutesDisplay}
                                </TableCell>
                                <TableCell className="center">
                                  {row.hoursPerCreditDisplay.split("m")[0]}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {modalDetails && (
            <Dialog open={modalVisible} onOpenChange={setModalVisible}>
              <DialogContent className="light max-w-[95%] h-full backdrop-blur-md">
                <DialogHeader>
                  <DialogTitle>{modalDetails.course.display_name}</DialogTitle>
                </DialogHeader>
                <ScrollArea className="h-full">
                  <Tabs defaultValue="activities" variant="tertiary">
                    <TabsList>
                      <TabsTrigger value="activities">Activities</TabsTrigger>
                      <TabsTrigger value="modules">Modules</TabsTrigger>
                    </TabsList>
                    <TabsContent value="activities" className="pt-6 pr-8">
                      <CourseActivity
                        course={modalDetails.course}
                        list={modalDetails.list}
                        expandable={true}
                      />
                    </TabsContent>
                    <TabsContent value="modules">
                      <CourseModule
                        course={modalDetails.course}
                        list={modalDetails.list}
                        expandable={true}
                      />
                    </TabsContent>
                  </Tabs>
                </ScrollArea>
              </DialogContent>
            </Dialog>
          )}
        </ScrollArea>
      )}
    </>
  );
});

export default ProgramActivities;
