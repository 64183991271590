import * as React from "react";

import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";
import { X } from "lucide-react";

import { buttonVariants } from "~/components/ui-rework/button";
import { cn } from "~/lib/utils";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed top-0 z-[1001] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]",
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const variants = {
  default: cn(
    "bg-gradient-one text-foreground border-ocean-70",
    "[&>svg]:text-success-foreground-r",
  ),
  success: cn(
    "success group border-success-r bg-success-r text-success-foreground-r",
    "[&>svg]:text-success-foreground-r",
  ),
  destructive: cn(
    "destructive group border-destructive-r bg-destructive-r text-destructive-foreground-r",
    "[&>svg]:text-destructive-foreground-r",
  ),
  warning: cn(
    "warning group border-warning-r bg-warning text-warning-foreground",
    "[&>svg]:text-warning-foreground",
  ),
};

const toastVariants = cva(
  cn(
    "group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden",
    "rounded-tr-xl rounded-bl-xl border p-6 shadow-lg transition-all",
    "data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none",
    "data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  ),
  {
    variants: {
      variant: variants,
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export const TOAST_VARIANT_KEYS = Object.keys(variants) as Array<
  keyof typeof variants
>;

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className)}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "inline-flex h-10 shrink-0 items-center justify-center rounded-full bg-transparent border px-6 text-sm font-bold ring-offset-background transition-colors",
      "hover:bg-button-outline-hover focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      "text-button-outline-foreground border-button-outline-ring",
      "group-[.destructive]:text-muted group-[.success]:text-muted group-[.warning]:text-muted",
      "group-[.destructive]:border-muted/40 group-[.success]:border-muted/40 group-[.warning]:border-muted/40",
      "group-[.destructive]:hover:border-destructive/30 group-[.success]:hover:border-success/30 group-[.warning]:hover:border-warning/30",
      "group-[.destructive]:hover:bg-destructive-r group-[.success]:hover:bg-success-r group-[.warning]:hover:bg-warning",
      "group-[.destructive]:hover:text-destructive-foreground-r group-[.success]:hover:text-success-foreground-r group-[.warning]:hover:text-warning-foreground",
      "group-[.destructive]:focus:ring-muted group-[.success]:focus:ring-muted group-[.warning]:focus:ring-muted",
      "group-[.destructive]:focus:ring-offset-destructive-r group-[.success]:focus:ring-offset-success-r group-[.warning]:focus:ring-offset-warning",
      className,
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "absolute right-2 top-2 rounded-md p-1 opacity-0 transition-opacity hover:text-foreground focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100",
      "group-[.destructive]:text-destructive-foreground-r group-[.success]:text-success-foreground-r group-[.warning]:text-warning-foreground",
      "group-[.destructive]:hover:text-destructive-foreground-r group-[.success]:hover:text-success-foreground-r group-[.warning]:hover:text-warning-foreground",
      "group-[.destructive]:focus:ring-muted group-[.success]:focus:ring-muted group-[.warning]:focus:ring-muted",
      "group-[.destructive]:focus:ring-offset-destructive-r group-[.success]:focus:ring-offset-success-r group-[.warning]:focus:ring-offset-warning",
      className,
    )}
    toast-close=""
    {...props}
  >
    <X className="h-4 w-4" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("text-sm font-semibold", className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("text-sm opacity-90", className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
