import * as ReportModels from "~/components/Reports/ReportModels";

export function getActivityTypeCategoryAndName(
  activityType: string,
): [string, string] {
  const actType = activityType?.replace("_", " ").toLowerCase();
  const assignmentAssessmentCategory = "Assignments/Assessments";
  const blendedLearningCategory = "Blended Learning";
  const learningActivitiesCategory = "Learning Activities";

  switch (actType) {
    case "project":
      return [assignmentAssessmentCategory, "Project"];

    case "discussion":
    case "discussion with research":
    case "discussion without research":
      return [assignmentAssessmentCategory, "Discussion"];

    case "quiz":
      return [assignmentAssessmentCategory, "Quiz"];

    case "assignment":
    case "graded assignment":
      return [assignmentAssessmentCategory, "Assignment"];

    case "exam":
      return [assignmentAssessmentCategory, "Exam"];

    case "lab":
      return [blendedLearningCategory, "Lab"];

    case "synchronous session":
      return [blendedLearningCategory, "Synchronous Session"];

    case "face to face session":
    case "face-to-face session":
      return [blendedLearningCategory, "Face-to-Face Session"];

    case "class meeting":
      return [blendedLearningCategory, "Class Meeting"];

    case "non graded practice":
    case "non-graded practice":
    case "non-graded practice activities":
      return [learningActivitiesCategory, "Non-Graded Practice"];

    case "reading":
      return [learningActivitiesCategory, "Reading"];

    case "multimedia":
      return [learningActivitiesCategory, "Multimedia"];

    case "other":
    default:
      return [learningActivitiesCategory, "Other"];
  }
}

export interface ITotSummary {
  category: string;
  type: string;
  tot_minutes: number;
  tot_minutes_display: string;
  tot_percent: number;
  label?: string;
}

export function getToteSummaryDefaults(): ITotSummary[] {
  const summaries: ITotSummary[] = [];

  summaries.push({
    category: "Assignments/Assessments",
    type: "Project",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Assignments/Assessments",
    type: "Discussion",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  // summaries.push({ category: "Assignments/Assessments", type: "Discussion with research", tot_minutes: 0, tot_minutes_display: "0m", tot_percent: 0, label: "" });
  // summaries.push({ category: "Assignments/Assessments", type: "Discussion without research", tot_minutes: 0, tot_minutes_display: "0m", tot_percent: 0, label: "" });
  summaries.push({
    category: "Assignments/Assessments",
    type: "Assignment",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  // summaries.push({ category: "Assignments/Assessments", type: "Graded assignment", tot_minutes: 0, tot_minutes_display: "0m", tot_percent: 0, label: "" });
  summaries.push({
    category: "Assignments/Assessments",
    type: "Quiz",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Assignments/Assessments",
    type: "Exam",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });

  summaries.push({
    category: "Blended Learning",
    type: "Synchronous Session",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Blended Learning",
    type: "Face-to-Face Session",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Blended Learning",
    type: "Class Meeting",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Blended Learning",
    type: "Lab",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });

  summaries.push({
    category: "Learning Activities",
    type: "Multimedia",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Learning Activities",
    type: "Non-Graded Practice",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Learning Activities",
    type: "Reading",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });
  summaries.push({
    category: "Learning Activities",
    type: "Other",
    tot_minutes: 0,
    tot_minutes_display: "0m",
    tot_percent: 0,
    label: "",
  });

  return summaries;
}

export function getActivityTypesSummary(
  activities: ReportModels.Activity[],
): ITotSummary[] {
  const summaries: ITotSummary[] = getToteSummaryDefaults();

  if (activities === null || activities.length === 0) return summaries;

  const totalMinutes = activities.reduce(
    (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
    0,
  );

  const uniqueActivityTypes = [
    ...new Set(activities?.map((activity) => activity.activity_type)),
  ];
  for (const activityType of uniqueActivityTypes) {
    const kvp = getActivityTypeCategoryAndName(activityType);
    const tot_minutes =
      activities
        ?.filter((x) => x.activity_type === activityType)
        .reduce(
          (a: number, b: ReportModels.Activity) => a + (b.tot_minutes || 0),
          0,
        ) || 0;
    const tot_minutes_display = ReportModels.formatMinutes(tot_minutes || 0);
    const tot_percent = Math.round((tot_minutes / totalMinutes) * 100);

    const summaryIndex = summaries.findIndex(
      (x) => x.category === kvp[0] && x.type === kvp[1],
    );
    if (summaryIndex !== -1) {
      summaries[summaryIndex].tot_minutes = tot_minutes || 0;
      summaries[summaryIndex].tot_minutes_display = tot_minutes_display;
      summaries[summaryIndex].tot_percent = tot_percent;
      summaries[summaryIndex].label = `${kvp[1]}`;
    }
  }

  return summaries;
}

export function getCategorySummaries(summaries: ITotSummary[]): ITotSummary[] {
  const result: ITotSummary[] = new Array<ITotSummary>();
  const categories = [...new Set(summaries.map((x) => x.category))];
  const totalMinutes = summaries.reduce(
    (a: number, b: ITotSummary) => a + (b.tot_minutes || 0),
    0,
  );

  categories.forEach((category) => {
    const typeSummary = summaries.filter((x) => x.category === category);
    const tot_minutes =
      typeSummary.reduce(
        (a: number, b: ITotSummary) => a + (b.tot_minutes || 0),
        0,
      ) || 0;

    const tot_minutes_display = ReportModels.formatMinutes(tot_minutes || 0);
    const tot_percent = Math.round((tot_minutes / totalMinutes) * 100);

    result.push({
      category: category,
      type: "",
      tot_minutes: tot_minutes,
      tot_minutes_display: tot_minutes_display,
      tot_percent: tot_percent,
      label: `${category} - ${tot_percent}%`,
    });
  });

  return result;
}
